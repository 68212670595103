.ludens-panel-container {
    width: 100%;
    height: 42px;
    min-height:42px;
    max-height:42px;
    background-color: #0c0e12;
    color: #EEE;
    display: flex;
    justify-content: space-between;
}
.ludens-circle-monitor {
    display: flex;
    height: 42px;
    align-items: center;
    padding: 0 10px;
    .circle-label {
        font-size: 15px;        
        display: flex;
        align-items: center;
    }
    .circle-slider { 
        margin: 0 20px;
        position:relative;
        width: 100%;
        display:flex;
        .circle-track {
            width: 900px;
            position:relative;
            max-width: 180px; 
            height: 14px;
            background-color: #aab1cd;
            border-radius: 12px;
            overflow: hidden;
            .slider-progress { 
                height: 14px;
                background-color: #6475b8;
            }
            .passed-time {
                position:absolute;
                font-size: 11px;
                font-weight:bold;
                color: #000;
                left: 10px;
                top: 0px;
            }
            .rest-time {
                position:absolute;
                font-size: 11px;
                font-weight:bold;
                color: #000;
                right: 10px;
                top: 0px;
            }
            @media screen and (max-width: 720px) {
                max-width: 120px;
                .passed-time {
                    display: none;
                }
            }
            @media screen and (max-width: 560px) {
                max-width: 70px;
            }
        }
    }
}
.pe-manitu-resource-single-cont {
    .resource {
        width: 45px;
        height: 45px;
        border-radius: 45px;
        background-color: #EEE;
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
        position:relative;
        cursor:pointer;
        .resource-count {
            position: absolute;
            bottom: 3px;
            right: -3px;
            font-size:9px;
            color: #FFF;
            background-color: #000;
            z-index: 3;
            padding: 3px;
        }
    }
    &.mobile {
        position:absolute; 
        bottom: 12px;
        right:10px;
        width: 45px;
        height: 45px;
    }
}
.pe-ludens-resource-dialog-container {
    padding: 0;
    display: flex;
    width: 100%;
    max-height: 700px;
    height: 100%;
    .pe-ludens-resource-panel {
        width: 190px;
        height: 100%; 
        display:flex;
        flex-direction: column;
        padding-bottom: 20px;
        .title {
            text-align: right;
            text-transform: uppercase;
            width: 190px;
            font-weight: 100;
            padding: 10px;
            font-family: 'Open Sans';
        }
        .description {
            font-size: 11px;
            text-align: right;
            padding: 10px;
            font-weight: 100;
            font-family: 'Open Sans';
        }
        .nominals {
            background-color: #373f61;
            padding:10px;
            color: #FFF;
            font-size: 18px;
            text-align: center;
            font-weight: 100;
            font-family: 'Open Sans';
        }
    }
    .pe-ludens-resource-content {
        flex-grow: 10;
        height: 100%;
    }
    @media screen and (max-width: 560px) {
        flex-direction: column;
        min-width: 100%;
        .pe-ludens-resource-panel {
            flex-direction:row;
            display:flex;
            min-width: 100%;
            height: 200px;
            .description {
                display:none;
            
            }
        }
    }
}
.pe-resources-operations {
    min-height:400px;  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 20px 0;
}

.pixel-btn {
    .pixel-btn-label {
        position:absolute;
        color: #FFF;
        bottom: 0;
        left:0;
        padding: .75em;
        text-align: center;
        text-transform: uppercase;
        min-width: 100%;
        font-size: 9px;
    }
    .pixel-btn-cont {
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: #2c526940; 
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; 
        &.sepian {
            filter: invert(2%) 
                sepia(168%) 
                saturate(200%) 
                hue-rotate(153deg) 
                brightness(60%) 
                contrast(120%);
        } 
    }
    &.active .pixel-btn-cont {
        background-color: #2c5269CC;
        filter: grayscale(0.0);
    }
    @media (max-width: 540px) {
        width: 30px!important; 
        position:relative;
        transition: 400ms all ease;
        height: 125px!important;
        .pixel-btn-label {
            transform: rotate(-90deg) translateX(50px) translateY(-40px);
            font-size: 7px;
            width: 100px; 
            transition: 400ms all ease;
        }
        .pixel-btn-cont {
            opacity:0;
            transition: 400ms all ease;
        }
        &.active {
            width: calc(100% - 90px)!important;
            .pixel-btn-label {
                transform: rotate(0);
                font-size: 9px; 
            } 
            .pixel-btn-cont {
                opacity:1;
            }
        }
    }
}