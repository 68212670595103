.pe-frametransition-container {
    position: relative;
    .pe-ft-prev-container {
        position: absolute;
        z-index: 1; 
        animation-fill-mode: both;
        animation-duration: 800ms!important;
    }
    .pe-ft-next-container {
        position: absolute;
        z-index: 2; 
        animation-fill-mode: both;
        animation-delay: 400ms!important;
        animation-duration: 800ms!important;
    }
}
 