.pe-ermakspaces-container {
    display: flex;
    width: 100%;
    position:relative;
    min-height: 100dvh;
    transition: all 900ms ease-in-out;
    .pe-ermakspaces-bg {
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: right;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all 900ms ease-in-out;
        mask-image: linear-gradient(to right, rgba(0,0,0,0) 36%, rgba(0,0,0,1));
    }
    .pe-ermakspaces{
        position: absolute;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        z-index: 1; 
        display: flex;
        .pe-ermakspaces-menu {
            width: 75px;
            height: 100%;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 560px) {
                width: 45px;
            }
            .pe-ermakspaces-btn {
                // height: 100%;
                min-height: 120px;
                width: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 12px;
                padding: 10px 0;
                cursor: pointer; 
                transition: all 900ms ease-in-out;
                @media screen and (max-width: 560px) {
                    width: 45px;
                }
                &.active {
                    flex-grow: 2; 
                    font-size: 15px;
                }
                div {
                    user-select: none;
                    transform-origin: 50% 50%;
                    transform: rotate(-90deg);
                    min-width: 120px;
                }
            }
        }
        .pe-ermakspaces-c {
            width: 100%;
            height: 100%;
            flex-grow: 100; 
            @media screen and (max-width: 560px) {
                width: calc(100% - 45px);
            }
        }
    }
}

.pe-space-container { 
   padding-bottom: 100px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   overflow-x: auto;
   .pe-ermak-map-container {
        position:relative;
        margin: 20px;
        .pe-ermak-map-title {
            font-size: 12px;
            font-weight: 800;
            font-family: "Open Sans";
            padding: 2px 10px;
            margin-bottom: 3px;
            color: #FFF;
            background-color: #111;
            display: inline-block;
        }
        .pe-ermak-map-row {
            position:relative;
            display:flex;
            .pe-ermakmap-cell-container {
                border: 1px solid #99999912;
                background-color: #99999956;
                position:relative;
                cursor: pointer;
                opacity: .75;
                padding: 13px;
                .id {
                    font-size: 9px;
                    opacity:.0;
                    padding: 3px;
                    position:absolute;
                }
                .pe-ermakcell-items-container {
                    width:  100%;
                    height: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &:hover {
                    opacity: 1;
                    border: 1px solid #99999967;
                    .id {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.pe-ermakslotgallery-container {
    display: flex; 
    overflow: hidden;
    margin: 20px;
    .pe-ermak-slot-gallery-item-container {
        width: 70px;
        height: 70px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.pe-ermak-map-cell-popover {
    padding: 20px;
    width: 120px;
    text-align: center;
    font-size: 11px;
}
.ermal-slot-portal {
    z-index: 1000!important;
}
.pe-ermak-slot-container {
    position: absolute;
    border: 1px solid #ffffffd0;
    transition: 300ms all ease-out;
    z-index: 21;
    box-shadow: 0 3px 2px 1px rgba(00,00,00,0.22), 0 8px 12px 1px rgba(00,00,00,0.11);
    overflow: hidden;
    .bg {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        opacity: .125;
    }
    .dt {
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index:1;
        .pe-ermak-slot-title {
            width: 100%;
            font-size: 12px;
            font-weight: 100;
            text-transform: uppercase;
            text-align:center;
            padding-top: 10px;
        }
        .pe-ermak-slot-content {
            width: 100%;
            flex-grow: 100;
            flex-direction: column;
            padding-bottom: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
            border:1px solid var(--force-mini-contast-color);
            .pe-ermakcell-items-container {
                width:  100%;
                height: 100%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .pe-slot-btn {
            width: 70px;
            height: 70px;
            display: flex;
            align-items:center;
            justify-content: center;
            flex-direction: column;
            cursor:pointer;
            font-size: 11px;
            opacity:.8;
            svg {
                width: 50px;
                height: 50px;
                path {
                    fill: #FFF;
                }
            }
            &:hover {
                opacity:1;
            }
            &.lock {
                pointer-events: none;
                opacity:.4;
            }
        }
    }
}