.pe-tennis-container {
    width: 800px;
    height: 400px;
    position: relative;
    background-color: #00000022;
    .attack-balls-cont {
        position: absolute;
        top:0;
        left:0;
        display: flex;
        .pe-fabula-ball-attack {
            width: 40px;
            height: 40px;
            background-color: paleGreen;
            margin: 2px;
        }
    }
    .defense-balls-cont {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        .pe-fabula-ball-defense {
            width: 40px;
            height: 40px;
            background-color: hotpink; 
            margin: 2px;
        }
    }
}