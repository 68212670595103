$monitorSize: 100px;
$iconSize: 40px;

.pe-manitu-mana-single-cont {
    position: relative;
    .mana { 
        position: relative;
        background-position: center;
        background-size: cover; 
        min-width: 22px;
        min-height: 22px;
        cursor:pointer;
        opacity: .75;
        &:hover {
            opacity:1;
        }
        .mana-count {
            position: absolute;
            right:-3px;
            bottom:-3px;
            padding:1px 4px;
            color:#FFF;
            background-color: #000;
            font-size: 9px;
        }
    }
    .pe-manitu-mana-s-indicate {
        width: 1px;
        height: 75%;
        position: absolute;
        background-color: #eeeeee2c;
        bottom: 0;
        left: -2px;
        .pe-mmsi {
            width: 1px;
            height: 100%;
            position: absolute;
            background-color: red;
            bottom: 0;
            left:0;
            transition: 1200ms height linear;
        }
    }
}
.monitor {
    width: $monitorSize;
    height:$monitorSize;
   
    &.datas {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 16px;
        padding:16px;
        
    } 
    &.widget.datas {
        width: auto;
        height: 100%;
        display: flex;
        padding:  0 13px;
        align-items: center;
        .pe-manitu-mana-single-cont {
            padding-bottom: 13px;
            margin-bottom: 13px;
            position: relative;
            .mana {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 29px;
                height: 29px;
                margin: 10px;
                z-index: 2;
                .mana-count {
                    opacity:0;
                }
            }
            .pe-manitu-mana-s-indicate {
                width: 55px;
                height: 55px;
                bottom: 13px;
                background-color: transparent;
                width: 55px;
                height: 55px;
                border-radius: 55px;
                overflow: hidden;
                width: 55px; 
                .pe-mmsi {
                    width: 55px;
                    opacity: .5;
                }
                &::before {
                    content: "";
                    position:absolute;
                    top: 1px;
                    left: 1px;
                    width: 53px;
                    height: 53px;
                    border-radius: 53px;
                    background-color: #0c0e12;
                    z-index: 1;

                }
            }
            &:hover {
                .mana-count {
                    opacity: 1;
                }
            }
        }
    }
    @media screen and (max-width: 720px) {
        & {
            width:  55px;
            height: 55px; 
            margin-bottom:30px;
        } 
        &.datas {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 3px;
            padding: 3px;
            height: 110px;
            margin-bottom: 0px;
            .mana { 
                background-size: auto;
                background-position: center;
                background-repeat: no-repeat;
                .mana-count {
                    right: 12px;
                    bottom: 0;
                }
            }
        }
    }
}
.left-container {
    width:150px;
    @media screen and (max-width: 720px) {
        & {
            width:  55px; 
        } 
    }
}
.manitu-left-menu-btn {
    position:absolute;
    background: radial-gradient(at center center, #182230 16%, #44516c0c 20%);
    filter: gray;
    filter: grayscale(.88);
    cursor:pointer;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 100%;
    height:100%;
    background-size: 100%;
    background-position: center;
    // border: 2px solid #777;
    color: #c5c7d9!important;
    transition: all 500ms ease-out;
    .label {
        position:absolute;
        bottom: 7px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight:900;
        transition: all 500ms ease-out;
        letter-spacing: 1px;
    }
}
.active > .manitu-left-menu-btn {
    background: radial-gradient(at center center, #070a0e 28%, rgba(43, 52, 70, 0) 66%);    
    color: #FFF!important; 
    background-size: 110%;
    background-position: center;
    filter: grayscale(0);
    .label { 
        letter-spacing: 3px;
    }
}
.pe-manitu-matrix-d-c { 
    width: 100%;
    height: 100%; 
    //border: 1px solid red;
    .pe-manitu-matrix-drag-cont {
        display: flex; 
        max-width: 100%;
        max-height: calc(100% - 40px);
        // overflow: auto;   
        // border:10px solid green;
        &::-webkit-scrollbar-track  {
            background: transparent;
            position: absolute;
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent; 
        }
        &::-webkit-scrollbar {
            width: 1px; 
            height: 1px; 
        } 
    }
    .pe-manitu-matrix-menu {
        height: 60px;
        display: flex;
        margin: 2px 70px;
        .descr {
            height: 20px;
            text-align:center;
            font-weight:900;
            font-size:11px;
        }
    }
}

.pe-manitu-matrix-container {
    position:relative;  
    display: flex;
    flex-direction: column;  
    transition: all 500ms ease-out;
    .row { 
        .cell {
            position:relative;
            width: var(--manituCellSize); 
            height: var(--manituCellSize);
            min-height: var(--manituCellSize);
            border: 1px solid #777;  
            margin: 1px;
            cursor: pointer;
            background-color: #cccee11a;
            transition: all 500ms ease-out;
            &.cell-by-action {
                background-color: #25262c;
                &:hover {
                    background-color: #0d0c0d;
                }
            }
            
            &:hover {
                background-color: #cccee15c;
            }
            .cell-defense { 
                position: absolute;
                bottom: 2px;
                right: 2px;
                img {
                    width: 50px;
                    height: 50px;
                }
                .defense-label{
                    position:absolute;
                    z-index:10;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width:40px;
                    height: 20px;
                    background-color:#191c2080;
                    color: #FFFFFF;
                    bottom: 0px;
                    right: 0px;
                    font-size: 10px;
                    font-weight: 700;
                    padding: 0 7px;
                }
            }
            .organoid-popover {
                position:absolute;
                top: 50%;
                left: 50%;
                .organoid {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    transform-origin: center;
                    transform: translate(-15px, -15px);
                    position:relative;
                }
            }
            &.cell-crashed {
                //background-color: #00000099;
                //box-shadow:inset 0 0 6px 2px rgba(256,00,00,.75);
                position: relative;
                &::before {
                    content: "";
                    position:absolute;
                    top:-1px;
                    left: -1px;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    background-image: url(../img/crash-cell.svg);
                    background-size:contain;
                    pointer-events: none;
                    opacity:.9;
                }
                border: none!important;
                .defense-label { 
                    color: #fe497c;
                    font-weight: 700;
                    display: none;
                }
                .organoid {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    transform-origin: center;
                    transform: translate(-15px, -15px);
                    position:relative;
                    position:absolute;
                    top: 50%;
                    left: 50%;
                    opacity: .5;
                    &::before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        top:0;
                        left:0;
                        background-image: url(../img/cross.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}
.men {
    transition: 400ms all ease-out;
    &.is_select {
        transform: scale(.95) translateX( 10px) translateY( 25px );
        opacity:.7;
    }
}
.man-div {
    transition: 400ms all ease-out;     
    transform-origin: 50% 100%;
    position:absolute; 
    &.is_select { 
       z-index:2;
       transform: scale(1.33)!important;
    }
}
.man {
    transition: 400ms all ease-out; 
    transform: scale(.98);
    transform-origin: 50% 100%;
    fill: #101115;
    stroke: #313236;
    stroke-miterlimit: 10;
    stroke-width: 3px;
    &.is_select {
       
        fill: #1C212B;
        stroke: #343842;
        z-index: 5;
        transform: scale(1.004);
    }
    &.is_unselect {
         
    }
} 

.tab-reseach {
    .bp5-tab-list {
        margin: 0 auto;
        max-width: 600px;
        width: 100%;
    }
    .bp5-tab {
        flex-grow: 0!important;
        height: 45px!important;
        display: flex;
    }
    .bp5-tab-panel {
        height: 100%;
        flex-grow: 100;
    }
}
.pe-manitu-pixel-card {
    padding: 0px;
    width: 200px;
    display: flex;
    flex-direction: column;
    margin: 6px;
    border: 1px solid #99999944;
    border-radius: 6px;
    overflow:hidden;
    .pe-manitu-pixel-title {
        padding: 13px;
        font-size:1.1em;
        font-weight: 100;
    }
    .pe-manitu-pixel-thumb {
        width: 200px;
        height: 200px;
        background-color: #00000012;
        background-size: cover;
        background-position: center;
        position: relative;
        .label {
            position:absolute;
            top:5px;
            right:5px;
            background-color: #111;
            color: #FFF;
            padding: 1px 8px;
            font-size: 11px;
            text-transform: uppercase;
        }
        .price {
            position:absolute;
            top:5px;
            left:5px;
            background-color: #582121;
            color: #FFF;
            font-weight:900;
            padding: 1px 8px;
            display: flex;
            align-items: center;
            .fas {
                font-size: 9px;
                margin-right:10px;
            }
        }
        .academy-discipline-label {
            position: absolute;
            bottom:2px;
            left:2px;
            font-size: 9px;
            font-weight: 500;
            letter-spacing: 3px;
            text-transform: uppercase;
            color:#FFF;
            background-color: #000;
            padding: 2px 3px 0 3px;
        }
    }
}
.pe-conquest-btn-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap:10px;
    @media screen and (max-width: 560px) {
        & {
            grid-template-columns: repeat(1, 1fr);
        } 
    }
    .pe-conquest-pixel-btn {
        background-position: center;
        position:relative;
        background-size: cover;
        text-transform: uppercase;
        position: relative;
        transition: 400ms opacity ease-out, 
            400ms box-shadow ease-out, 
            400ms transform ease-out; 
        box-shadow: 0px 0px 0px 0 #000000;
        font-size: 11px;
        font-weight: 100;
        border-radius: 6px;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color:  rgba(36, 24, 71, 0.724);
            background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(23,23,43,1) 100%); 
            transition: 400ms opacity ease-out;
            border: 1px solid #FFFFFF99;
            border-radius: 6px;
        }
        .label {
            background-color: #000;
            color: #FFF;
            padding: 2px 10px;
            z-index: 2;
            transition: 400ms letter-spacing ease-out;
            margin: 5px;
            letter-spacing: 1px;
        }
        &:hover {
            box-shadow: 1px 3px 4px 0 #000000;
            transform: translateY(-1px);
            .label {
                letter-spacing: 4px;
            }
            &::after {
                opacity: .75;
                
            }
        }
    }
}
.pe-manitu-container {
    flex-grow: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    .reseach-tabs-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .tab-reseach {
            height: 100%;
        }
    }
}
.hero-btn {
    position: absolute;
    height: 140px;
    left: -50px; 
    bottom:0;
    overflow: hidden;
    transition: 700ms height ease-out;
    z-index: 110;
    &.active {
        height: 180px;
        pointer-events: none;
        svg .man {
            fill: #8698e4;
        }
    } 
    @media screen and (max-width: 560px) {
        transform: scale(.7) translateY(38px) translateX(-95px);
    }
}
.opponents-btn {
    position: absolute;
    height: 140px;
    right: -50px;
    left: auto;
    bottom:0;
    overflow: hidden;
    transition: 700ms all ease;
    // z-index: 1;
    &.active {
        height: 180px; 
        transform: scale(1.1) translateY(-10px);
        svg .man {
            fill: #606a96; 
        }
        svg .men  {
            transform: scale(0.85) translateX(19px) translateY(80px);
        }
    }
    @media screen and (max-width: 560px) {
        transform: scale(.7) translateY(30px) translateX(111px);
        &.active {
            transform: scale(0.65) translateX(111px) translateY(50px);
        }
    }
}
.pe-manitu-pixels-monitor-container {
    height: 80px;
    display: flex;
    align-items: end;
    width: 390px;
    overflow: hidden;
    transform: translateY(-38px) translateX(-55px);
    .pe-manitu-pixel-monitor-btn {
        transform: translateY(25px);
        padding-left: 0.25rem;
        padding-bottom: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        transition: 500ms transform ease-out;
        img {
            width: 90px;
            height: 90px;
            transform-origin: 50% 100%;
            transition: 500ms all ease-out;
        }
        &:hover {
            transform: translateY(20px);
            .pe-pixel-btn-popover { 
                opacity: 1;
                transform: translateY(-5px);
            }
        }
        &.active img {
            width: 95px;
            height: 95px;
            transform: translateY(-2px);
        }
        .pe-pixel-btn-popover {
            position: absolute;
            pointer-events: none;
            top: 65px;
            left: -35px;
            width: 165px;
            font-size: 8px;
            text-transform: uppercase;
            text-align: center;
            transform: translateY(0px);
            transition: 500ms transform ease-out, 250ms opacity ease-out; 
            opacity: 0;
            z-index:1;
            span {
                background-color: #000000;
                color: #EEE!important;
                padding: 5px 15px;
                border-radius:20px;
            }
        }
    }
    @media screen and (max-width: 992px)  {
        transform: translateY(-38px) translateX(0px);
    }
}
.pe-manitu-test-card {
    position: relative; 
    background-position: center;
    background-size: cover;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:  rgba(36, 24, 71, 0.724);
        background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(23,23,43,1) 100%); 
        transition: 400ms opacity ease-out;
        border: 1px solid #FFFFFF99; 
    }
    a {
        z-index:1;
    }
    .titled {
        position:absolute;
        min-width: 100%;
        min-height: 100%;
        top:0;
        left:0;
        padding: 0px;
        display:flex;
        justify-content: center;
        align-items: end;
        text-align: center;
        z-index:2;
        span {
            color: #EEE;
            width: 100%; 
            padding: 4px 15px;
        }
    }
    .cat-label {
        position: absolute;
        top:10px;
        left: 10px;
        font-size:8px;
        text-transform: uppercase;
        padding: 4px 11px;
        background-color: #5c1010;
        color:#FFF;
    }
}
.pe-add-pixel-btn {
    width: 100%;
    text-transform: uppercase;
    padding: 5px 15px;
    color: #aebdde;
    &.active, 
    &:hover {
        color: #FFF!important;
    }
    @media screen and (max-width: 560px) {
        font-size: 10px;
    }
}
.pe-manitu-test-filter-btn {
    font-size: 12px;
    @media screen and (max-width: 560px) {
        font-size: 11px;
    }
}
.pe-pixel-btn {
    padding: 11px; 
    font-size:11px;
    text-transform: uppercase;
    border-bottom: 1px solid #99999944;
    width: 100%;
    &:last-of-type {
        border-bottom: 1px solid transparent;
    }
}
.pe-manitu-opponent-avatar-btn {
    width: 64px;
    height: 64px;
    margin: 2px; 
    position: relative;
    .pe-manitu-opponent-avatar {
        width: 54px;
        height: 54px;
        border-radius: 100px;
        background-size: cover;
        position: absolute;
        top: 5px;
        left: 5px; 
        transform-origin: 50% 50%;
        transition: 300ms all ease;
        background-color: #444;
        opacity:.8;
        &:hover {
            transform: scale(1.04);
            opacity: 1;
        }
    }
}
.pe-matrix-chpock-container {
    // border: 4px dotted #FF0000;
    position: absolute;
    z-index: 5;
    pointer-events: none;
}
.pe-matrix-hover-container { 
    position: absolute;
    z-index: 4;
    pointer-events: none;
    display: none;
    &.active {
        display: block;
        .pe-matrix-hover {
            position: absolute;
            transform: translateX(-18px) translateY(-18px);
            transition: 100ms top ease-out, 100ms left ease-out;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            display: flex;
            font-size: 16px;
            opacity:.6;
            justify-content: center;
            align-items: center;
            background-color: #000;
        }
    }
}
.pe-matrix-animation-container {
    // border: 4px dotted #FF0000;
    position: absolute;
    z-index: 3;
    pointer-events: none;
}
.pe-manitu-undermatrix-close-collapse {
    position: relative;
    overflow: hidden;
    transition: 300ms width ease-out;
    width: 0px;
    height: 100%;
    &.open {
        width: 55px;
    }
}
.pe-manitu-animated-label {
    position: absolute;
    padding: 10px;
    font-size: 12px;
    font-weight: 700;
    background-color: #000;
    transform-origin: 50%;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
@keyframes up-pens { 
    from { 
        top: 150px; 
        opacity:0;
    } 
    25% {
        transform: translateX(10px);
    }
    33% {
        opacity:1;
    }
    50% {
        transform: translateX(-7px);
    }
    75% {
        transform: translateX(5px);
    }
    to {
        top: 0; 
        opacity:0;
    }  
}
@keyframes up-pens2 { 
    from { 
        top: 150px; 
        opacity:0;
    } 
    25% {
        transform: translateX(-10px);
    }
    33% {
        opacity:1;
    }
    50% {
        transform: translateX(7px);
    }
    75% {
        transform: translateX(-3px);
    }
    88% {
        opacity: 1;
    }
    to {
        top: 0; 
        opacity:0;
    }  
}
.pe-manitu-monitor-animation {
    position: absolute;
    pointer-events: none;
    top: -200px;
    left: -40px;
    width: 100px;
    height: 200px;
    z-index: 1; 
    display: none;
    &.animate { 
        display: block;
        //border: 1px dotted red;
        .pens {
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            position:absolute;
            left: 30px;
            width: 30px;
            height: 30px;
            background-color: #353b2b; 
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 900;
            opacity: 0;
            border: 1px solid transparent;
            &.alt0 {                
                animation-name: up-pens; 
            }
            &.alt1 {                
                animation-name: up-pens2; 
            }
        }
    }
}