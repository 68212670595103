$cubeSize: 50px;
$cubeTranslate: $cubeSize / 2 + 3;
$cubeWidth: $cubeSize;
$cubeWidth: $cubeSize;
$cubeDotsWidth: $cubeSize - 14;
$cubeGrid: $cubeDotsWidth / 3;
$cubeDotMargin: 1px;
$cubeDotWidth: $cubeDotsWidth / 3 - $cubeDotMargin * 2;

/* SPEECH SUBTITLES */
$audio-anime-time: 3s;
$box-size: 500px;
@media screen and (max-width: 900px) {
    $box-size: 340px;
}
@media screen and (min-width: 900px) {
    $box-size: 500px;
}
$clip-distance: .005;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;
$main-color: #69ca62;

.pe-fabula-container {
    position:relative;
    display:flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .swiper {
        height: 100%;
    } 
}
.pe-fabula-story-card {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;  
    padding: 0px;  
    cursor: pointer;
    .pe-fabula-story-card-bg {
        padding: 20px; 
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        opacity: .33;
        transition: 300ms all ease-out;
        background-color: #444;
    } 
    .pe-fabula-story-card-content {
        position: absolute;
        top: 15px;
        left: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #FFFFFF77;
        flex-direction: column;
    }
}
.pe-story-cover-container {
    position:relative;
    display:flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .crd {
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 400px;
        max-height: 400px;
        background-size: cover;
        background-position: center;
        padding: 10px;
        &:before {
            content: "";
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background-color: var(--drop-select-color);
            border: 1px solid var(--force-contast-color);
            opacity: .75;
        }
        
    }
}
.avatar-square-btn {
    background-size: cover;
    background-position: center;
    width: 150px;
    height: 150px;
    margin: 5px;
    border: 1px solid #FFFFFFAA;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 11px;
    text-align: center;
    padding: 4px;
    cursor: pointer;
    opacity: .8;
    border-radius: 4px;
    color: #FFF;
    &:hover {
        opacity: 1;
    }
}
@keyframes closing {
	0% {opacity: 1;}
	45%, 100% {opacity: 0;}
}
@keyframes opening {
	100% {opacity: 1;}
	0%, 75% {opacity: 0;}
}
.pe-fabula-menu-cont {
    position: absolute; 
    bottom: 0; 
    .pe-fabula-menu-item {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #111;
        color: #FFFFFF;
        bottom: 0px;
        left: 60px; 
        animation-name: closing;
        animation-duration: .3s;
        animation-fill-mode: both;
        animation-delay: 0.0s;
        cursor: pointer; 
        &:hover {
            background-color: #161212;
            transform: scale(1.1)
        }
        &:active {
            background-color: #251212;
            transform: scale(1.0)
        }
        &.opened {
            animation-name: opening;
            animation-duration: .3s;
            animation-fill-mode: both;
            animation-delay: 0.0s;
            //transition: bottom 800ms ease-out, left 800ms ease-out, transform 100ms ease-out; 
        }
    } 

}   
.pe-fabula-menu-cont.first .pe-fabula-menu-item {
    transition-delay: 0; 
    transition: bottom 300ms ease-out, left 300ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 13px;
        left: -58px;
    } 
}
.pe-fabula-menu-cont.second  .pe-fabula-menu-item  {
    transition-delay: 100ms;
    transition: bottom 450ms ease-out, left 450ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 60px;
        left: -30px;
    }
} 
.pe-fabula-menu-cont.third  .pe-fabula-menu-item {
    transition-delay: 200ms;
    transition: bottom 600ms ease-out, left 600ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 86px;
        left: 17px;
    }
} 
.pe-fabula-menu-cont.fourth  .pe-fabula-menu-item {
    transition-delay: 300ms;
    transition: bottom 750ms ease-out, left 750ms ease-out, opacity 500ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 92px;
        left: 71px;
    }
} 
.pe-fabula-menu-cont.fifth  .pe-fabula-menu-item {
    transition-delay: 400ms;
    transition: bottom 750ms ease-out, left 750ms ease-out, opacity 500ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 73px;
        left: 121px;
    }
} 
.pe-fabula-menu-cont.sixth  .pe-fabula-menu-item {
    transition-delay: 500ms;
    transition: bottom 750ms ease-out, left 750ms ease-out, opacity 500ms ease-out, transform 100ms ease-out;
    &.opened{
        bottom: 32px;
        left: 158px;
    }
} 

.pe-taleform-container {
    position: relative;
    overflow: auto;
    outline: none!important; 
}
.tile-portal {
    z-index: 3;
    .bp5-overlay-backdrop {
        display: none;
    }
}
.pe-fabula-map {
    background-size: cover;
    background-position: center center;
    outline: none!important;
    position:relative;
    overflow: hidden;
    .pe-fabula-map-bg {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        // transition: 1000s all ease-out;
    }
    .pe-story-map-tile {
        position: absolute;
        border: 1px solid rgba(200, 200, 206, 0.433); 
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center; 
        z-index: 2;
        &:hover {
            border: 1px solid rgba(200, 200, 206, 1); 
        }
        &.disabled {
            border-color: #00000099;
            background-color: #00000077; 
        }
    }
}
.pe-fabula-map-portal {
    z-index: 98!important;
    position:absolute!important;
    top:  0!important;
    left: 0!important;
    width: 100vw!important;
    height: 100vh!important;
    backdrop-filter: blur(2px)!important;
    &::before { 
        content:"";
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 300px;
        background: linear-gradient(to bottom,  rgb(79, 104, 152) 20%,rgba(125,185,232,0) 100%);
        pointer-events: none;
    }
    .bp5-overlay-backdrop {
        background-color: #111111CC!important;
    }
    .pe-fabula-quest-form-container {
        width: 100%;
        max-width: 900px;
        height: 100%;
        max-height: 370px;
        margin-top:auto;
        margin-bottom:auto;
        margin-left:auto;
        margin-right:auto;
        .pe-speech-engine-container {
            top: 320px;
            .pe-speech-single{
                font-size: 14px;
            }
        }
    }
    .pe-story-intro-container { 
        //height: var(--main-height);
        max-height: 370px;
    }
}
.pe-fabula-menu-map {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    z-index: 22;
    .pe-fabula-menu-map-bg {
        position:fixed;
        width: 100vw;
        height: 100vh;
        background-color: #00000000;
    }
}
.pe-fabula-pult-portal {
    z-index: 100!important;
    position:absolute!important;
    top: calc(100vh - 80px)!important;
    left: calc(50% - 180px)!important;
    width: 160px!important;
    height: 80px!important;
    border-radius: 160px 160px 0 0!important;
    background-color: #111;
    border: 1px solid #777777;
    border-bottom: 0px!important;
    cursor:pointer!important;
    user-select: none!important;
    .pe-fabula-pult-backdrop { 
        pointer-events: none!important;
        opacity: 0!important;
    }
    .pe-fabula-pult-container { 
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        margin-top: 5px;
        width: 160px;
        height: 70px; 
        flex-direction:column;

        outline: none!important;
        .pe-round-slider-container {
            position: absolute;
            top: -126px;
            left: -122px;
            height: 200px;
            overflow: hidden;
            pointer-events: none;
            z-index:1;
        }
        .pe-fabula-timer-container { 
            position: absolute;
            top:0;
            left:0;
            font-size: 10px;
            .pe-fabula-timer {
                position: absolute;
                left: 0px;
                top: -10px;
                text-align: center;
                opacity: 1;
                width: 160px !important;
                height: 80px !important;
                border-radius: 160px 160px 0 0 !important;
                background-color: #111;
                background-size: cover;
                background-position: center;
                &::before {
                    content: "";
                    position: absolute;
                    width: 160px !important;
                    height: 80px !important;
                    border-radius: 160px 160px 0 0 !important;
                    top: 0;
                    left: 0;
                    background: linear-gradient(to bottom, rgba(125,185,232,0.02) 30%,rgba(0,0,0,0.72) 100%);
                }
                .pe-fabula-timer-tablo {
                    position: absolute;
                    left: 0px;
                    top: 70px;
                    text-align: center;
                    width: 160px !important;
                    z-index: 2;
                }
            }
        }
    }
}
.pe-fabula-stuff-portal {
    position: absolute!important;
    top: calc(100vh - 70px)!important;
    left: calc(50% + 90px)!important;  
    z-index: 104!important;  
    width: 250px!important;
    height: 60px!important;
    .pe-fabula-stuff-backdrop {
        display: none; 
    }
    .pe-story-stuffs-container {
        position:relative;
        display: flex;
    }
}
.pe-story-stuff-icon-container {
    position: relative;
    margin-right: 10px;
    position: relative;
    background-color: #111;
    width: 60px;
    height: 60px;
    cursor: pointer;
    .pe-story-stuff-icon {
        width: 60px;
        height: 60px;
        background-color: #111;
        border: 1px solid #FFFFFF55;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        &.execute {
            animation: stuff-execute 1.0s linear both;
        }
    }
    .pe-stuff-icon-label {
        position: absolute;
        top: -22px;
        background-color: #000000;
        padding: 2px 10px;
        white-space: nowrap;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #FFF;
        font-size: 11px; 
        opacity:0;
        pointer-events: none;
        transition: 200ms opacity ease-out;
    }
    &:hover > .pe-stuff-icon-label {
        opacity: 1;
    }
}
@keyframes stuff-execute
    {
        0% {
            transform: rotate( 0deg ) scale(1.0) translateY( 0px );
        }  
        33% {
            transform: rotate( 0deg ) scale(1.0) translateY( -25px );
        } 
        50% {
            transform: rotate( 0deg ) scale(1.0) translateY( -10px );
        } 
        66% {
            transform: rotate( 0deg ) scale(1.0) translateY( -25px );
        }
        100% {
            transform: rotate( 0deg ) scale(1.0) translateY( 0px );
        }
    }
.pe-fabula-character-portal {
    position: absolute!important;
    top: calc(100vh - 37px)!important;
    left: calc(50% - 460px)!important;  
    z-index: 104!important;  
    width: 200px!important;
    height: 35px!important; 
    .pe-fabula-character-backdrop {
        display: none; 
    }
    .pe-hero-character-menu-container {
        position:relative;
        display: flex;
        justify-content: flex-end;
        .picon {
            width: 35px;
            height: 35px;
            background-position: 6px 2px;
            background-size: 65%;
            background-repeat: no-repeat;
            position:relative;
            border-radius: 35px;
            background-color: #FFFFFF77;
            margin-left: 10px;
            .picon-value {
                position: absolute;
                left:10px;
                bottom:0;
                width: 14px;
                height: 14px;
                display: flex;
                justify-content: center;
                align-items: center; 
                color: #FFF;
                font-size: 8px;
            }
        }
    }

}
.pe-fabula-map-tile-container {
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.8156862745);
    transition: 300ms all ease-out;
    z-index: 21;
    box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.22), 0 8px 12px 1px rgba(0, 0, 0, 0.11);
    backdrop-filter: blur(3px);
    background-color: #ffffff11;
    overflow: hidden;    
    outline: none!important;
    .bg {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        position:relative;
        &::before {
            content: "";
            position:absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            //background-color: #00000077;
        }
    }
    .bg-episode {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        position:relative;
    }
    .dt {
        position: absolute;
        top: 0;
        width: 100%;
        .pe-fabula-map-tile-title {
            text-align: center;
            text-transform: uppercase;
            font-family: "Open Sans";
            z-index:2;
            position: absolute;
            width: 100%;
            span {
                padding: 2px 8px;
                background-color: #111111CC;
                p {
                    margin-bottom: 2px;
                }
            }
        }
        .pe-fabula-map-tile-content {
            text-align: center; 
            font-family: "Open Sans";
            font-size: 12px;
            z-index:2;
            top: 40px;
            text-align: center;
            position: absolute;
            width: 100%;
            span {
                padding: 2px 8px;
                background-color: #111111AA;
                p {
                    margin-bottom: 2px;
                }
            }
            
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: calc(50% - 250px);
            width: 500px;
            background: radial-gradient(ellipse at center 9%, rgb(0 0 0 / 59%) -9%, rgba(125, 185, 232, 0) 60%);
            height: 120px;
            background-blend-mode: multiply;
        }
    }
    .pe-fabula-arrow-top { 
        top: 55px;
        left: calc(50% - 15px); 
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
    .pe-fabula-arrow-bottom { 
        bottom: 30px;
        left: calc(50% - 15px); 
        clip-path: polygon(100% 0, 1% 0, 50% 100%);
    }
    .pe-fabula-arrow-left {
        bottom:  calc(50% - 15px); 
        left: 10px; 
        clip-path: polygon(100% 0, 100% 100%, 0 50%);
    }
    .pe-fabula-arrow-right {
        bottom:  calc(50% - 15px); 
        right: 10px;
        clip-path: polygon(0 0, 0 100%, 100% 50%);
    }
    .pe-fabula-map-tile-arrow {
        position:absolute;
        background-color: #ff0d0daa;
        transition: all 200ms ease-out;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover {
            background-color: #ff0d0d;
        }
        &:active {
            transform: scale(.95);
        }
        @media screen and (max-width: 560px) {
            width: 20px!important;
            height: 20px!important;
        }
    }
    .pe-fabula-episode-label {
        position: absolute;
        white-space: nowrap;
        width: 40px;
        height: 40px;
        color: #EEE;
        font-weight: 800;
        font-size: 18px;
        font-family: "Open Sans";
        font-weight: 100;
        
        transform: translate(-15px, -15px);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        z-index: 3;
        &::before {
            content: "";
            position: absolute;
            z-index: 1; 
            border-radius: 40px 40px 0 40px;
            transform: rotate(45deg);
            background-color: #111; 
            width: 40px;
            height: 40px; 
        } 
        &.protected::before {
            background: rgb(13, 32, 127);
        }
        &.defensed::before {
            background: rgb(104, 50, 3);
        }
        &.broken::before {
            background: rgb(108, 10, 10);
        }
        .pe-fabula-episode-label-label {
            z-index: 2;
        }
        
    }
} 
.back-drop-opaciry-02 {
    background-color: rgba(17, 20, 24, 0.27)!important;
}
.pe-fabula-mine-hero-label {
    background-color: #981616;
    border-color: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    cursor: pointer;
}
.pe-tile-episode-menu-container {
    position: absolute;
    top:auto;
    bottom: 10px;
    right:auto;
    left: calc(50% - 16px);
    .main-menu {
        display: grid;
        gap: 2px;
        position:relative;
        
    }
    .main-menu-back {
        display: grid;
        gap: 2px;
        position:relative;
        transform: translateX(30px);
        
    } 
}
.pe-story-break-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.pe-fabula-media-content-container {
    position: absolute;
    top: 100px;
    left:0;
    height: calc(100% - 200px);
    display: flex;
    width: 100%;
    overflow-y: auto; 
    &::before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: #00000055;
        pointer-events: none;
    }
    .pe-html-container {
        max-width: 1240px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        color: #FFF;
        z-index: 10;
        padding-right: 20px;
        padding-bottom: 200px;
        h2 {
            font-size:33px;
        }
        img {
            margin: 10px;
        }

        &::-webkit-scrollbar 
		{
			width: 40px;
			position: absolute;
			left: 0;
		}
		&::-webkit-scrollbar-track 
		{
			background: #ffffff22;
            width: 2px;
            margin-top:100px;
            margin-bottom: 20px;
			position: absolute;
			border-radius: 40px;
            -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.855); 
		}
		&::-webkit-scrollbar-thumb 
		{
			background-color: #ffffffEE; 
			border-radius: 36px;
            height: 36px;
			width: 36px;
		}
		&::-webkit-scrollbar 
		{
			width: 36px; 
			cursor:pointer;
		} 
    }
}
.pe-multi-screen {
    position:absolute; 
}
.pe-multi-screen-item {
    position:absolute;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    cursor:pointer;
    filter: saturate(0) brightness(.6);
    border: 1px solid transparent;
    transition: 250ms brightness ease-out;
    &:hover {
        opacity: .8;
        filter: saturate(.9);
    }
    &.active {
        opacity:1;
        filter: saturate(1) brightness(1);
        border-color: #FFFFFF;
    }
}
.pe-fabula-overflow-y-auto {
    overflow-y: auto;
    &::-webkit-scrollbar 
    {
        width: 40px;
        position: absolute;
        left: 0;
    }
    &::-webkit-scrollbar-track 
    {
        background: #ffffff22;
        width: 2px;
        margin-top:100px;
        margin-bottom: 20px;
        position: absolute;
        border-radius: 40px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.855); 
    }
    &::-webkit-scrollbar-thumb 
    {
        background-color: #ffffffEE; 
        border-radius: 36px;
        height: 36px;
        width: 36px;
    }
    &::-webkit-scrollbar 
    {
        width: 36px; 
        cursor:pointer;
    }
}
.pe-tile-protection-screen-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.fabula-hack-container,
.fabula-break-container {
    flex-grow: 1;
}
.pe-mini-game-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $cubeSize + 100;
    min-height: $cubeSize + 10;
    .def-hack-container {
        .dice-container { 
            display: flex;      
            flex-wrap: wrap;  
            align-items: center;
            justify-content: center;
            margin-left: 30px;
            margin-right: 30px;
            width: 340px;
            height: 100%;
        }
    } 
    .dice {
        .mycube
        {
            width: $cubeWidth;
            height: $cubeWidth;
            position: relative; 
            margin : $cubeSize / 3;
        }
        .cube
        {
            width: 100%;
            height: 100%;
            position: absolute;
            transform-style: preserve-3d; 
            transition: transform 2s ease-out;
            &.cube0 {
                transform: rotateY(-42deg) rotateZ(20deg) rotateX(-20deg) translateZ(0px);
                //animation: cube 12s linear infinite;
            }
            &.cube1 {
                transform: rotateY(-2deg) rotateZ(20deg) rotateX(-0deg) translateZ(0px);
                //animation: cube 11s linear infinite;
            }
            &.cube2 {
                transform: rotateY(-240deg) rotateZ(-20deg) rotateX(-90deg) translateZ(0px);
                //animation: cube 8s linear infinite;
            }
            &.cube3 {
                transform: rotateY(-60deg) rotateZ(-90deg) rotateX(-20deg) translateZ(0px);
                //animation: cube 7s linear infinite;
            }
            &.cube4 {
                transform: rotateY(-340deg) rotateZ(-460deg) rotateX(-0deg) translateZ(0px);
                //animation: cube 9s linear infinite;
            }
            &.cube5 {
                transform: rotateY(-600deg) rotateZ(-160deg) rotateX(-180deg) translateZ(0px);
                //animation: cube 11s linear infinite;
            }
            &.cube6 {
                transform: rotateY(-440deg) rotateZ(-420deg) rotateX(-190deg) translateZ(0px);
                //animation: cube 13s linear infinite;
            }
            &.cube7 {
                transform: rotateY(-640deg) rotateZ(-540deg) rotateX(-290deg) translateZ(0px);
               // animation: cube 7s linear infinite;
            }
            &.cube8 {
                transform: rotateY(-240deg) rotateZ(-260deg) rotateX(-290deg) translateZ(0px);
                //animation: cube 14s linear infinite;
            }
            &.cube9 {
                transform: rotateY(-740deg) rotateZ(-20deg) rotateX(-90deg) translateZ(0px);
                //animation: cube 12s linear infinite;
            }
            &.cube10 {
                transform: rotateY(-140deg) rotateZ(-120deg) rotateX(-490deg) translateZ(0px);
                //animation: cube 8s linear infinite;
            }
            &.cube0 {
                transform: rotateY(-330deg) rotateZ(-330deg) rotateX(-590deg) translateZ(0px);
                //animation: cube 7s linear infinite;
            }
            &.to1 {
                transform: rotateY(0deg) rotateZ(-20deg) rotateX(0deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.to2 {
                transform: rotateY(0deg) rotateZ(10deg) rotateX(180deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.to3 {
                transform: rotateY(-90deg) rotateZ(0deg) rotateX(180deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.to4 {
                transform: rotateY(-270deg) rotateZ(-0deg) rotateX(-92deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.to5 { 
                transform: rotateY(0deg) rotateZ(58deg) rotateX(-90deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.to6 {
                transform: rotateY(-180deg) rotateZ(-42deg) rotateX(-90deg) translateZ(0px);
                transition: transform 2s ease-out;
            }
            &.anti > div {

                background-color: #c2cced!important;
                .fabula-dice-dot::before {
                    background-color: #2a3a4e!important;
                }
            }
        }
        .cube > div
        {
            width: $cubeWidth;
            height: $cubeWidth;
            position: absolute; 
            border-radius: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 62px;
            color: #c2cced;
            background-color: #305787!important;
            backface-visibility: hidden;
            .dots {
                width: $cubeDotsWidth;
                height: $cubeDotsWidth;
                position:relative;
                display: grid;
                background-color: transparent!important;
                transform: translateZ(1px);
                display: grid; 
                grid-template-columns: $cubeGrid $cubeGrid $cubeGrid;
                grid-template-rows:  $cubeGrid $cubeGrid $cubeGrid;
                .fabula-dice-no-dot {
                    display: block;
                }
                .fabula-dice-dot { 
                    display: block;
                    &::before {
                        content: "";                    
                        position: absolute;
                        margin-left: $cubeDotMargin;
                        margin-top: $cubeDotMargin;
                        width: $cubeDotWidth;
                        height: $cubeDotWidth;
                        border-radius: $cubeDotWidth;
                        background-color: #c2cced;
                    }
                }
            } 
        }
        .a
        { 
            transform: rotateY(0) translateZ($cubeTranslate); 
        }
        .b
        { 
            transform: rotateY(180deg) translateZ($cubeTranslate); 
        }
        .c
        { 
            transform: rotateY(90deg) translateZ($cubeTranslate); 
        }
        .d
        { 
            transform: rotateY(-90deg) translateZ($cubeTranslate); 
        }
        .e
        { 
            transform: rotateX(90deg) translateZ($cubeTranslate); 
        }
        .f
        { 
            transform: rotateX(-90deg) translateZ($cubeTranslate); 
        }

        @keyframes cube
        {
            0% {
                transform: rotateY( 1080deg ) rotateZ( 0deg ) rotateX( 1080deg ) translateZ( 0px );
            }
            33.3% {
                transform: rotateY(0deg ) rotateZ( 1080deg ) rotateX( 0deg ) translateZ( 0px );
            }
            66.6% {
                transform: rotateY(1080deg) rotateZ( 0deg ) rotateX( 1080deg ) translateZ( 0px );
            }
            100% {
                transform: rotateY( 0deg ) rotateZ( 1080deg ) rotateX( 0deg ) translateZ( 0px );
            }
        }
    }
}
.pe-tile-episode-npc-sprite {
    position: absolute;
    top:40px;
    left: 40px;
    width: 80px;
    height: 80px;  
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: 200ms all ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    .pe-tile-episode-npc-sprite {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        filter: brightness(.8);
    }
    .pe-tile-episode-npc-label {
        width: 30px;
        height: 30px;
        background-color: rgb(173, 62, 11);
        color: #FFF;
        border: 1px solid #FFFFFF66;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(45deg);
        z-index:3;
    }
    &:hover {
        .pe-tile-episode-npc-sprite{
            filter: brightness(1); 
        }
        .pe-tile-episode-npc-label {
            background-color: rgb(237, 92, 14);
        }
    }
}
.pe-speech-engine-container {
    position: absolute;
    top: 70%;
    left:0;
    width: calc(100% - 60px); 
    margin: 0 30px;
    .pe-speech-avatar-cont {
        position: absolute;
        top: -10px;
        left: -10px;
        display: flex; 
        align-items: flex-start;
        .pe-speech-avatar {
            width: 70px;
            height: 70px;
            background-color: #444;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 3;
        }
        .pe-speech-display-name { 
            background-color: #0f0f15;
            padding: 4px 10px;
            z-index: 3;
        }
    }
    .pe-speech-single-title {
        position: absolute;
        top:- 40px;
        font-size: 22px;
        color: #FFFFFF;
        padding: 4px 12px;
        background: #00000099;
    }
    .pe-speech-single { 
        background-color: rgba(24, 26, 33, 0.66);
        color: #FFF;
        padding: 30px 20px 20px 75px;
        min-height: 100px; 
        position: absolute;
        top: 10px;
        font-size: 16px;
        font-weight: 100;
        font-family: Open sans, serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1px rgba(7, 7, 7, 0.95);
        z-index:2;
        &.audio-active::before {
            content: "";
            z-index: 1;
            margin: -2px;
            border-top: 10px solid red;
            animation: clipMe $audio-anime-time linear infinite; 
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        } 
        &.LEFT {
            width: calc(50% - 20px);
            left:0;
        }
        &.LEFT_1 {
            width: $clip-size;
            left: 0;
        }
        &.LEFT_2 {
            width: $clip-size;
            left: 33%;
        }
        &.RIGHT_1{
            width: $clip-size;
            left: 66%;
        }
        &.RIGHT {
            width: calc(50% - 20px);
            left: 50%;
        } 
    }
}
@keyframes clipMe {
    0%, 100% {clip: rect(0px, $clip-size, $path-width, 0px); }
    25% {clip: rect(0px, $path-width, $clip-size, 0px); }
    50% {clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px); }
    75% {clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width); }
}
.pe-fabula-settings-form-container {
    display:flex;
    min-height: 400px;
    width: 100%;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    .pe-audio-settings-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .pe-fabula-audio-setting-btn {
            margin: 2px;
            width: 130px;
            height: 130px;
            background-color:#bbd0e821;
            //border: 1px solid #bbd0e8;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;
            transition: all 200ms ease-out;
            &.active {
                background-color:#bbd0e85a;
                border-color: transparent;
            }
            &:hover {
                background-color:#71829662;
            }
            & img {
                opacity: .5;
                transition: opacity 200ms ease-out;
            }
            &.active img {
                opacity: 1;
            }
        }
    }
}
.pe-fabula-switch-menu-portal {
    z-index: 100!important;
    
    .pe-fabula-switch-menu-backdrop {
        pointer-events: none!important;
        opacity: 0!important;
    }
    .pe-fabula-switch-menu-container{
        position: fixed;
        top: 30px;
        left: 50%;
        min-width: 300px;
        height: 1px;
        transform: translateX(-280px); 
        display: flex;
        justify-content: center; 
        .pe-fabula-switch-menu-btn { 
            padding:  0px 30px;
            display: flex;
            align-items: center;
            color: #ffffffd5;
            text-transform: uppercase;
            height: 33px;
            margin: 0px;
            cursor: pointer;
            opacity: .8;
            border-right: 1px solid #ffffff6c;
            z-index: 2;
            &:hover {
                opacity: 1;
            }
            &:last-of-type {
                border-color: transparent;
            }

        }
    }
}
.pe-hero-fullness-menu-container {
    
    position: absolute;
    bottom: 60px;
    left: calc(50% - 0px);
    .pe-fabula-health-slider {
        width: 120px;
        height: 4px;
        background-color: #111111a7;
        position: relative;
        overflow: hidden;
        border-radius: 2px;
        .pe-fabula-health {
            position: absolute;
            top:0px;
            left:0;
            width: 120px;
            height: 4px;
            background-color: #5cfd38;
        }
    }
}
.pe-fabula-staff-label-cont {    
    position: absolute;
    .pe-fabula-staff-label {
        width: 40px;
        height: 40px; 
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff; 
        cursor: pointer;
        &::before {
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 30px 30px 0 30px;
            transform: rotate(45deg);
            background-color: purple;
        }

    }
}
.pe-story-intro-slide-container {
    width: 100%;
    height: 100%;
    max-height: var(--main-height);
    background-position: center;
    background-size: cover;
}
.pe-story-intro-container { 
    height: var(--main-height);
    //max-height: 370px;
}



.pe-fabula-quest-form-container {
    width: 100%; 
    height: var(--main-height);
    margin-top:auto;
    margin-bottom:auto;
    margin-left:auto;
    margin-right:auto;
     
}
.pe-sound-volume-engine-container {
    position: absolute;
    display: flex;
    align-items: flex-start;
    bottom: 50px;
    left: 10px;
    width: 240px;
    .pe-sound-volume {
        min-width: 40px;
        min-height: 40px;
        margin-right: 40px;
        &.no::before {
            content: "";
            position: absolute;
            top: 20px;
            left: -5px;
            width: 50px;
            height: 3px;
            background-color: #FF0000;
            transform: rotate(45deg);
        }
        &.no::after {
            content: "";
            position: absolute;
            top: 20px;
            left: -5px;
            width: 50px;
            height: 3px;
            background-color: #FF0000;
            transform: rotate(-45deg);
        }
    }
    .sound-input {
        height: 40px;
        margin-top: -1rem;
    }
}